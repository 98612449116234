import React from 'react'
// import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client';
import App from './App.jsx'
import {BrowserRouter} from "react-router-dom";
import AuthContextProvider from "./context/AuthContext";
import './index.css'
import {ServerContextProvider} from "./context/ServerContext.jsx";

createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <BrowserRouter>
            <ServerContextProvider>
                <AuthContextProvider>
                    <App/>
                </AuthContextProvider>
            </ServerContextProvider>
        </BrowserRouter>
    </React.StrictMode>,
)