import './App.css'
import Navbar from "./Components/Navbar/Navbar.jsx";
import {Route, Routes} from "react-router-dom";;
import CommingSoon from "./Pages/ComingSoon/CommingSoon.jsx";
import Login from "./Pages/Login/Login.jsx";
import Register from "./Pages/Register/Register.jsx";

function App() {
    return (
        <>
            {/*<Navbar/>*/}
            <Routes>
                <Route path="/" element={<CommingSoon/>}/>
                {/*<Route path="/test" element={<CommingSoon/>}/>*/}
                {/*<Route path="/login" element={<Login/>}/>*/}
                {/*<Route path="/register" element={<Register/>}/>*/}
            </Routes>
        </>
    )
}

export default App