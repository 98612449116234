import React, { createContext, useState } from 'react';

export const ServerContext = createContext(null);

export function ServerContextProvider({ children }) {
    const [value, setValue] = useState("Test Value");


    const serverData = {
        value: value,
        setValue: setValue,
    };
    // The value provided to ServerContext.Provider should match the type ServerContextJSONValue
    return (
        <ServerContext.Provider value={serverData}>
            {children}
        </ServerContext.Provider>
    );
}