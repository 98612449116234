import './CommingSoon.css';
function CommingSoon() {

    return (
        <div className="curtain">
            <img src={"/curtains.jpg"} alt="curtain" className="curtain-img"/>
            <div className="text-container">
                <a className="link" href={"https://docs.google.com/forms/d/e/1FAIpQLSfsMfYW7tMXMA8brwef-4IvJd5sb1uG0dMBY6h5-8pOxoSMOg/viewform"} >Vragenlijst</a>
                <h1 className="soon">Binnenkort openen wij onze gordijnen!</h1>
            </div>
        </div>
    )
}

export default CommingSoon;